import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CTA from "components/footer/footer-cta"
import NewsletterSignup from "components/footer/newsletter-signup"
import Link from "components/transition-link"
import getRelativeUrl from "utils/getRelativeUrl"
import PrimaryNavigation from "components/primary-navigation"
import Social from "components/social/social"
import { toPhoneNumber } from "utils/formatting"

const Footer = () => {
  const { legalMenu, primaryMenu, wp } = useStaticQuery(graphql`
    query Footer {
      legalMenu: wpMenu(locations: { eq: LEGAL }) {
        ...MenuItems
      }
      # primaryMenu: wpMenu(locations: { eq: PRIMARY }) {
      #   ...MenuItems
      # }
      wp {
        options {
          options {
            companyAddress
            mapLocation
            emailAddresses {
              generalEmail
            }
            companyPhoneNumber
            callToAction {
              button {
                title
                url
              }
              heading
            }
            newsletter {
              inputPlaceholder
              heading
              buttonText
            }
          }
        }
      }
    }
  `)
  const {
    companyAddress,
    mapLocation,
    emailAddresses,
    companyPhoneNumber,
  } = wp.options.options
  return (
    <>
      <CTA />

      <div className="footer__separator bg-midnight md:px-7">
        <div className="container">
          <div className="border border-bottom"></div>
        </div>
      </div>

      <div className="pt-12 pb-16 md:pt-16 md:pb-26 bg-midnight">
        <div className="container">
          <footer className="c-footer footer md:px-7">
            <div className="mb-12 lg:mb-0 c-footer__address">
              <div className="mb-4 font-medium leading-normal text-20px xs:text-2xl ff-temps">
                Made in London.
              </div>
              <address className="mb-10 not-italic">
                <a
                  className="inline-block text-white transition hover:text-accent"
                  href={mapLocation}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: companyAddress }}
                  ></div>
                </a>
              </address>
              <div className="block mb-2">
                <a
                  className="inline-block font-medium text-white text-md tg-underline tg-underline--white"
                  href={toPhoneNumber(companyPhoneNumber)}
                >
                  {companyPhoneNumber}
                </a>
              </div>
              <div className="block">
                <a
                  className="inline-block font-medium text-white text-md tg-underline tg-underline--white"
                  href={`mailto:${emailAddresses.generalEmail}`}
                >
                  {emailAddresses.generalEmail}
                </a>
              </div>
            </div>
            {/* /end left */}

            <div className="mt-2 align-start text-md">
              <PrimaryNavigation
                linkColours={"font-semibold text-white hover:text-accent"}
              />
            </div>

            <div className="c-footer__newsletter">
              <NewsletterSignup />
            </div>

            <div className=" lg:mt-28 c-footer__legal md:order-none sm:text-left">
              © Together {new Date().getFullYear()}
            </div>

            <div className="c-footer__social sm:flex-row">
              <Social />

              <nav className="mt-3 sm:ml-22 sm:mt-0">
                {legalMenu.menuItems.nodes.map((item, i) => (
                  <Link
                    to={getRelativeUrl(item.url)}
                    key={`menuItem${item.id}`}
                    activeClassName="text-accent"
                    className={`text-sm text-white ${i > 0 && "ml-6"}`}
                  >
                    {item.label}
                  </Link>
                ))}
              </nav>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

export default Footer
