export const graphikAsterisk = text => {
  if (!text) return null
  return text.replace(
    "*",
    `<span class="inline-block text-accent font-sans">*</span><br />`
  )
}

export const toPhoneNumber = phoneNumber => {
  const diallingCode = /\(\d\)/
  const companyTel = `tel:${phoneNumber
    .replace(diallingCode, "")
    .replace(/\s/g, "")}`
  return companyTel
}

export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const toSnakeCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("_")

export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? parseInt(result[1], 16) +
        " " +
        parseInt(result[2], 16) +
        " " +
        parseInt(result[3], 16)
    : null
}

// Credit: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
