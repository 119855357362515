import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import getRelativeUrl from "utils/getRelativeUrl"
import Star from "assets/star.svg"
import TransitionLink from "components/transition-link"

const PrimaryNavigation = ({
  location = "",
  clickFunction,
  linkColours = "",
}) => {
  const { wpMenu } = useStaticQuery(graphql`
    query PrimaryNavigation {
      wpMenu(locations: { eq: PRIMARY }) {
        menuItems {
          nodes {
            url
            id
            label
          }
        }
      }
    }
  `)

  const { nodes } = wpMenu.menuItems

  return (
    <nav className="flex main-nav align-center" onClick={() => {
        clickFunction && clickFunction()
      }}>
      {nodes.map((item, i) => (
        <TransitionLink
          key={item.id}
          to={getRelativeUrl(item.url)}
          activeClassName={`is-active`}
          partiallyActive={true}
          className={
            (i > 0 ? "md:ml-8 ml-6" : " ") +
            " relative font-medium text-xs flex items-center normal-case md:uppercase  " +
            linkColours
          }
        >
          {item.label}
          <Star
            className="mx-auto text-accent nav-star"
            style={{ width: "8px" }}
          />
        </TransitionLink>
      ))}
    </nav>
  )
}

export default PrimaryNavigation
