import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PrimaryNavigation from "components/primary-navigation.js"
import Star from "assets/star.svg"
import Social from "components/social/social"

const MobileNav = ({ isShown, isScrolled, hideMobileNav }) => {
  const data = useStaticQuery(graphql`
    query MobileNavigation {
      primaryMenu: wpMenu(locations: { eq: PRIMARY }) {
        ...MenuItems
      }
      socialMenu: wpMenu(locations: { eq: SOCIAL }) {
        ...MenuItems
      }
      wp {
        options {
          options {
            emailAddresses {
              generalEmail
            }
            companyPhoneNumber
          }
        }
      }
    }
  `)
  const { emailAddresses, companyPhoneNumber } = data.wp.options.options
  const diallingCode = /\(\d\)/
  const companyTel = `tel:${companyPhoneNumber
    .replace(diallingCode, "")
    .replace(/\s/g, "")}`

  if (isShown) {
    document.body.classList.remove("nav-scroll")
  } else if (isScrolled) {
    document.body.classList.add("nav-scroll")
  }

  return (
    <>
      <div
        className={`sm:hidden mobile-nav bg-grey fixed inset-0 overflow-hidden overflow-y-auto ${isShown &&
          "is-open"}`}
      >
        <div className="flex flex-col justify-between min-h-full">
          <div className="container flex flex-col flex-auto pt-24 pb-16 nav-container">
            <h1 className="my-4 font-semibold text-17px text-raven">
              Where to?
            </h1>
            <PrimaryNavigation clickFunction={hideMobileNav} />
          </div>

          <div
            className={`bottom-container container flex flex-col items-center bg-white text-midnight py-12`}
          >
            <Social type={`icon`} colour={"black"} />
            <a
              className="mt-8 mb-2 text-sm font-medium underline text-midnight hover:text-accent"
              href={`mailto:${emailAddresses.generalEmail}`}
            >
              {emailAddresses.generalEmail}
            </a>
            <a
              className="text-sm font-medium text-midnight hover:text-accent"
              href={companyTel}
            >
              {companyPhoneNumber}
            </a>
            <Star className="h-auto my-8 text-accent mobile-nav__star" />

            <div className="text-sm font-medium text-center">
              © Together {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileNav
