import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Social = ({ type = "text" }) => {
  const { socialMenu } = useStaticQuery(graphql`
    query Social {
      socialMenu: wpMenu(locations: { eq: SOCIAL }) {
        menuItems {
          nodes {
            url
            label
            id
          }
        }
      }
    }
  `)

  return (
    <div className="flex mt-10 social">
      {socialMenu.menuItems.nodes.map(
        (item, i) =>
          (type === "text" && (
            <TextSocial item={item} key={`text-social-${item.id}`} />
          )) ||
          (type === "icon" && (
            <IconSocial item={item} key={`icon-social-${item.id}`} />
          ))
      )}
    </div>
  )
}

const TextSocial = ({ item }) => (
  <a
    href={item.url}
    target="_blank"
    rel="noopener noreferrer"
    className={"block social-icon transition tg-underline tg-underline--white"}
  >
    {item.label}
  </a>
)

const IconSocial = ({ item }) => (
  <a
    href={item.url}
    target="_blank"
    rel="noopener noreferrer"
    className={
      "block social-icon transition footer-" + item.label.toLowerCase()
    }
    style={{ height: "18px", marginRight: "20px" }}
  >
    <img
      className="block h-full"
      src={`/social/${item.label.toLowerCase()}-black.svg`}
      alt={item.label}
    />
  </a>
)

export default Social
