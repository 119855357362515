import React, { Component } from "react"
import TransitionLink from "./transition-link"
import PrimaryNavigation from "components/primary-navigation.js"
import MobileNav from "components/mobile-nav/mobile-nav"
import Logo from "assets/new-together-logo.svg"

export default class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasMounted: false,
      navIsOpen: false,
      isScrolled: false,
      isMobile: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.hideMobileNav = this.hideMobileNav.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.isMobile = this.isMobile.bind(this)
  }

  handleClick() {
    this.setState(({ navIsOpen }) => ({
      navIsOpen: !!!navIsOpen,
    }))
  }

  hideMobileNav() {
    this.setState({
      navIsOpen: false,
    })
  }

  onScroll() {
    // this.setState({
    //   isScrolled: !this.state.navIsOpen && window.scrollY > 5
    // });
  }

  isMobile() {
    // return window.matchMedia('(max-width: 568px)').matches;
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.isScrolled !== prevState.isScrolled) {
    //   document.body.classList[this.state.isScrolled ? 'add' : 'remove']('nav-scroll');
    // }
  }

  componentDidMount() {
    this.setState({ hasMounted: true })
    if (typeof window == "undefined") return

    // this.onScroll(); // Check scroll position on page load

    // window.addEventListener('resize', this.isMobile); // Check breakpoint on resize

    // // If mobile, listen for scroll
    // this.isMobile &&
    //   window.addEventListener('scroll', this.onScroll, { passive: true });
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.onScroll);
    // window.removeEventListener('resize', this.isMobile);
  }

  render() {
    return (
      <>
        <header
          id="js-header"
          className={`c-header ${
            this.state.navIsOpen ? "mobile-nav-is-open" : ""
          } ${this.state.hasMounted ? "opacity-100" : "opacity-0"}`}
        >
          <div className="c-header__background"></div>
          <div className="container flex items-center justify-between align-center">
            <div onClick={this.hideMobileNav}>
              <TransitionLink to="/" className="c-header__logo">
                <Logo id="js-header-logo" className="w-auto together-logo" />
              </TransitionLink>
            </div>

            <div className="hidden sm:flex align-center ">
              <PrimaryNavigation
                location={`header`}
                linkColours={"text-midnight hover:opacity-75"}
              />
            </div>

            <div className="block sm:hidden">
              <button
                onClick={this.handleClick}
                className={`tg-hamburger ${this.state.navIsOpen &&
                  "is-active active"}`}
                type="button"
              >
                <div>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </div>
          </div>
        </header>

        <MobileNav
          isShown={this.state.navIsOpen}
          isScrolled={this.state.isScrolled}
          hideMobileNav={this.hideMobileNav}
        />
      </>
    )
  }
}
