import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Controller, Scene } from "react-scrollmagic"
import { Tween } from "react-gsap"
import Link from "components/transition-link"
import getRelativeUrl from "utils/getRelativeUrl"
import Star from "assets/star.svg"

const CTA = () => {
  const { wp } = useStaticQuery(graphql`
    query {
      wp {
        options {
          options {
            callToAction {
              heading
              button {
                title
                url
              }
            }
          }
        }
      }
    }
  `)
  const { heading, button } = wp.options.options.callToAction
  return (
    <section
      id="footer-cta"
      className="text-white py-22 footer-cta bg-midnight"
    >
      <div className="container">
        <div className="flex flex-col items-center justify-between md:px-7 sm:flex-row">
          <h3 className="flex items-center mb-8 font-serif leading-snug text-center sm:mb-0 sm:mr-4 text-40px sm:text-left">
            <Controller>
              <Scene
                triggerElement="#footer-cta"
                triggerHook="onEnter"
                duration="100%"
                pin={false}
              >
                {progress => (
                  <Tween
                    to={{
                      rotation: "720deg",
                      ease: "Strong.easeOut",
                    }}
                    totalProgress={progress}
                    paused
                  >
                    <div className="tween footer-cta__star">
                      <Star className="w-full text-accent" />
                    </div>
                  </Tween>
                )}
              </Scene>
            </Controller>
            {heading}
          </h3>
          <Link
            className="font-serif leading-normal whitespace-no-wrap transition cta-button button button--large button--midnight"
            to={getRelativeUrl(button.url)}
          >
            {button.title}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CTA
