/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "components/header.js"
import Footer from "components/footer.js"
import Headroom from "react-headroom"

const Layout = ({ children }) => {
  return (
    <div id="js-scroll-container">
      <div id="js-scroll-section">
        <Headroom disableInlineStyles wrapperStyle={{ maxHeight: "0" }}>
          <Header />
        </Headroom>
        <div className="flex flex-col min-h-screen">
          <div className="flex flex-col flex-1">
            <main className="flex-1">{children}</main>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
