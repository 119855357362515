import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link';


export default (props) => (
  <TransitionLink
    to={props.to}
    exit={{ state: { ...props.state }, length: .4 }}
    entry={{ state: { ...props.state }, length: 0, delay: .4 }}
    {...props}
  >
    {props.children}
  </TransitionLink>
)