import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"

const NewsletterSignup = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        options {
          options {
            newsletter {
              inputPlaceholder
              heading
              buttonText
            }
          }
        }
      }
    }
  `)
  const {
    inputPlaceholder,
    buttonText,
    heading,
  } = data.wp.options.options.newsletter
  const [isSent, setIsSent] = useState(false)

  const [formMessage, setFormMessage] = useState("")

  const newletterFormSubmit = async e => {
    e.preventDefault()
    const emailValue = e.currentTarget.querySelector("input[type=email]").value
    const response = await addToMailchimp(emailValue)
    if (response.result === "success") {
      setIsSent(true)
    }
    setFormMessage(response.msg)
    return false
  }

  return (
    <div className="text-center md:text-left">
      <h5
        className="mb-3 font-medium sm:text-17px sm:mb-4"
        style={{ letterSpacing: "-0.27px" }}
      >
        {heading}
      </h5>
      <form
        onSubmit={newletterFormSubmit}
        action="#"
        className="footer-newsletter-form"
      >
        <input
          type="email"
          className="flex-auto px-4 mr-3 overflow-hidden bg-white rounded text-midnight"
          placeholder={inputPlaceholder}
        />
        <button
          type="submit"
          className="px-12 overflow-hidden font-serif text-base font-medium text-center text-white transition border border-white rounded hover:bg-grey hover:text-midnight "
        >
          {buttonText}
        </button>
        <div
          className="absolute inset-0 flex items-center justify-center text-center bg-white sent-message text-midnight"
          style={{ display: isSent ? "flex" : "none" }}
        >
          {formMessage}
        </div>
      </form>

      <div
        className="mt-2 text-sm error-message"
        style={{ display: !isSent && formMessage.length ? "block" : "none" }}
        dangerouslySetInnerHTML={{ __html: formMessage }}
      />
    </div>
  )
}

export default NewsletterSignup
