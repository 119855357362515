/* eslint-disable */
import "stylesheets/style.scss";

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

export const onInitialClientRender = () => {
  if (typeof document == 'undefined') return;

  window.ss = require('smooth-scroll')('a[href*="#"]', {
    header: '#js-header',
    offset(anchor) {
      if (window.innerWidth >= 900)
        return 0;

      const twoColImage = anchor.querySelector('.tc__image')
      if (twoColImage && /(products?|brand(s|ing)?|websites?)$/ig.test(anchor.id)) {
        return -twoColImage.clientHeight || 0;
      }

      return 0;
    },
  });

  [...document.querySelectorAll('.initial-preloader')].map(el => {
    el.classList.remove('is-shown');
    setTimeout(()=>{
      el.parentNode.removeChild(el);
    }, 450);
  });
}

const scrollTo = (id) => () => {
  const el = document.querySelector(id);
  if (el) {
    return window.scrollTo(0, el.getBoundingClientRect().top - 81);
  }
  return false;
}

let transitionDelay = 0;
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {

  if (location.action === "PUSH") {
    if (location.pathname.includes('/work/') && location.pathname[6] != undefined) {
      // transitionDelay = 2000;
    }
    // window.setTimeout(() => {
      window.scrollTo(0, 0);
    // }, transitionDelay);
  } else {
    // const savedPosition = getSavedScrollPosition(location);
    // window.setTimeout(
    //   () => window.scrollTo(...(savedPosition || [0, 0])),
    //   transitionDelay
    // )
  }
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), transitionDelay + 100);
  }
}